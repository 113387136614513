<template>
  <div class="main-page">
    <div class="main-page__top">
      <Header> </Header>
      <Top
        :title="`
                Нужен займ?<br/>
                Нет ничего проще
            `"
      />
    </div>
    <Advantages />
    <Steps id="how-get" />
    <Conditions id="conditions" />
    <Reviews />
    <Questions id="faq" />
    <Footer />
  </div>
</template>

<script>
import Top from "@index/components/common/top/Top";
import Header from "@index/components/main/header/Header";
import sbg from "@index/mixins/sbg";
// import store from '@index/store'

import "./main-page.scss";
// import Cookies from 'js-cookie'

export default {
  name: "Main",
  mixins: [sbg],
  mounted() {
    if (!this.isSbg) return;

    setTimeout(() => {
      const href = location.href;

      history.pushState(null, document.title, "/final");
      history.pushState(null, document.title, href);
    });
  },
  components: {
    Header,
    Top,
    Advantages: () => import("@index/components/main/advantages/Advantages"),
    Steps: () => import("@index/components/main/steps/Steps"),
    Conditions: () => import("@index/components/main/conditions/Conditions"),
    Reviews: () => import("@index/components/main/reviews/Reviews"),
    Questions: () => import("@index/components/main/questions/Questions"),
    Footer: () => import("@index/components/main/footer/Footer"),
  },
  // beforeRouteEnter(to, from, next) {
  //   if (Cookies.get('sbg-cpa')) {
  //       next()
  //   } else {
  //       store.commit('application/load', false)
  //       next({
  //           name: 'ContactInfo'
  //       })
  //   }
  // }
};
</script>

<style lang="scss">
@import "@index/components/main/conditions/conditions";
@import "@index/components/main/advantages/advantages";
@import "@index/components/main/steps/steps";
@import "@index/components/main/reviews/reviews";
@import "@index/components/main/questions/questions";
@import "@index/components/main/footer/footer";
</style>
